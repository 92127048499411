<template>
  <div class="order-state">
    <div class="bread">首页 / 销售订单管理 / 发货单</div>
    <div class="state">
      <div class="state-id">
        <div>订单号：{{ myData.id }}</div>
      </div>
      <div class="state-box">
        <div class="box-left">
          <div>
            <span class="ml10">订单状态：</span>
            <div v-show="myData.state == 'PAY'">待确认</div>
            <div v-show="myData.state == 'PAYMENT'">支付完成</div>
            <div v-show="myData.state === 'SHOP_AUDIT'">审核通过</div>
            <div v-show="myData.state == 'DELIVERY'">已发货</div>
            <div v-show="myData.state == 'RECEIVED'">收货</div>
            <div v-show="myData.state == 'FINISH'">完成</div>
            <div v-show="myData.state == 'NEW'">新订单</div>
            <div v-show="myData.state == 'CANCEL'">取消</div>
          </div>
        </div>
        <div class="box-right">
          <div class="step">
            <a-steps :current="myStep" size="small" labelPlacement="vertical">
              <a-step>
                <template slot="title">下单</template>
                <span slot="description" v-if="myData.createTime">{{
                  moment(myData.createTime).format("YYYY-MM-DD HH:mm:ss")
                }}</span>
              </a-step>
              <a-step v-show="myData.payTypeCode === 'onlinePayment' && myData.paymentTime">
                <template slot="title">支付</template>
                <span slot="description">{{ moment(myData.paymentTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
              </a-step>
              <a-step v-show="myData.deliveryTime">
                <template slot="title">已发货</template>
                <span slot="description">{{ moment(myData.deliveryTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
              </a-step>
              <a-step v-if="myData.payTypeCode === 'onlinePayment' && myData.receivedTime">
                <template slot="title">收货</template>
                <span slot="description">{{ moment(myData.receivedTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
              </a-step>
              <a-step v-show="myData.finishTime">
                <template slot="title">订单完成</template>
                <span slot="description">{{ moment(myData.finishTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
              </a-step>
              <a-step v-show="myData.returnsAuditTime">
                <template slot="title">取消</template>
                <span slot="description">{{ moment(myData.returnsAuditTime).format("YYYY-MM-DD HH:mm:ss") }}</span>
              </a-step>
            </a-steps>
          </div>
          <div class="free-space" style="display:flex;justify-content: space-between;">
            <div class="shoper-info mt20" style="flex:4">
              <div>收货人：{{ myData.consigneeName }}</div>
              <div>联系方式：{{ myData.consigneePhone }}</div>
              <div>收货地址：{{ myData.consigneeAddress }}</div>
              <div>支付方式：{{ myData.payTypeCode === "onlinePayment" ? "在线支付" : "线下支付" }}</div>
              <div class="remark">订单备注：{{ myData.remark }}</div>
              <div v-if="myData.returnsPic">
                <span style="float: left;">退货文件：</span>
                <a-upload
                  :multiple="true"
                  :action="actions"
                  listType="picture-card"
                  :fileList="fileList"
                  @preview="handlePreview"
                  @remove="
                    () => {
                      return false;
                    }
                  "
                >
                  <div v-if="fileList.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
                <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </div>
            <div v-if="myStep === 3 || 4" class="shoper-info mt20" style="flex:2">
              <div>发票类型:普通类型</div>
              <div>发票抬头:{{ invName }}</div>
              <div v-if="invName !== '个人'">纳税人识别号:{{ invTin }}</div>
              <div>发票内容:商品明细</div>
              <div v-if="myData.invoiceRemark">发票信息备注:
                <span style="width:100%">
                  {{myData.invoiceRemark}}
                </span>
              </div>
              <div>快递公司: {{ myData.logisticsCompany }}</div>
              <div>快递单号: {{ myData.logisticsNo }}</div>
            </div>
          </div>
        </div>
      </div>
      <a-table class="mt20" :columns="columns" :dataSource="data" rowKey="id" :pagination="false">
        <template slot="name" slot-scope="text, record">
          <img :src="getPhoto(record.picDef)" width="50px" height="50px" />
          <span class="ml10">{{ text }}</span>
          <span>(规格: <span style="color:#e69b47">{{record.productPropertyList[0].value}}</span>)</span>
        </template>
        <template slot="payTypeCode" slot-scope="text">
          <span>{{ text === "onlinePayment" ? "在线支付" : "线下支付" }}</span>
        </template>
        <template slot="unitPrice" slot-scope="text">
          <span style="color: red;">￥{{ text }}</span>
        </template>
        <template slot="totalPrice" slot-scope="text, record">
          <span style="color: red;">￥{{ record.num * record.unitPrice }}</span>
        </template>
      </a-table>
      <!-- <div class="box-down">
        <div class="list">
          <div class="list-head my-head">
            <div class="name table-head">商品</div>
            <div class="list-name">
              <div class="mglt table-head">支付方式</div>
              <div class="mglt table-head">单价</div>
              <div class="mglt table-head">数量</div>
              <div class="mglt table-head">总价</div>
            </div>
          </div>
        </div>
        <div class="list">
          <div
            class="list-head"
            v-for="item in myData.products"
            :key="item.id"
            style="border-top:1px solid #e6e6e6;"
          >
            <div style="flex:0.45" class="list-info">
              <div style="width:50px;height:50px;margin-right: 20px;">
                <img :src="getPhoto(item.picDef)" width="50px" height="50px" />
              </div>
              <div>{{item.name}}</div>
            </div>
            <div class="list-name" style="padding-top: 28px;">
              <div v-if="myData.payTypeCode === 'onlinePayment'" class="mglt">在线支付</div>
              <div v-else>货到付款</div>
              <div class="mglt">￥{{item.unitPrice}}</div>
              <div class="mglt">{{item.num}}</div>
              <div class="mglt">￥{{item.unitPrice * item.num}}</div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { getOrderDetail } from '@/serve/order'
import global from 'global'
import moment from 'moment'
export default {
  name: 'orderState',
  computed: {
    actions () {
      return global.file.url + '/sys/fs/'
    },
    name () {
      return this.myData.consigneeName + ' ' + this.myData.consigneePhone
    },
    payType (type) {
      return type === 'onlinePayment'
    }
  },
  data () {
    return {
      myStep: 0,
      id: this.$route.params.id,
      myData: [],
      aa: true,
      bb: true,
      cc: true,
      dd: true,
      ee: true,
      isCancle: false,
      columns: [
        {
          dataIndex: 'name',
          key: 'name',
          title: '商品',
          width: '40%',
          scopedSlots: { customRender: 'name' }
        },
        // {
        //   dataIndex: 'payTypeCode',
        //   key: 'payTypeCode',
        //   title: '支付方式',
        //   scopedSlots: { customRender: 'payTypeCode' }
        // },
        {
          dataIndex: 'unitPrice',
          key: 'unitPrice',
          title: '单价',
          scopedSlots: { customRender: 'unitPrice' }
        },
        {
          dataIndex: 'num',
          key: 'num',
          title: '数量',
          scopedSlots: { customRender: 'num' }
        },
        {
          dataIndex: 'totalPrice',
          key: 'totalPrice',
          title: '总价',
          scopedSlots: { customRender: 'totalPrice' }
        }
      ],
      data: [],
      fileList: [],
      previewImage: '',
      previewVisible: false,
      invName: '',
      invTin: ''
    }
  },
  methods: {
    getPhoto (id) {
      return global.file.url + '/sys/fs/' + id + '?s=1'
    },
    handlePreview (file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
    handleCancel () {
      this.previewVisible = false
    },
    moment,
    init () {
      getOrderDetail(this.id).then(res => {
        this.invName = res.invName
        this.invTin = res.invTin
        this.data = res.products
        if (res.returnsPic) {
          this.fileList.push({
            uid: -1,
            name: 'photo.jpg',
            status: 'done',
            url: this.getPhoto(res.returnsPic)
          })
        }
        if (res.state === 'CANCEL') {
          // 取消
          this.myStep = 5
        } else if (res.state === 'NEW') {
          // 下单
          this.myStep = 0
        } else if (res.state === 'PAYMENT') {
          // 支付
          this.myStep = 1
        } else if (res.state === 'DELIVERY') {
          // 发货
          this.myStep = 2
        } else if (res.state === 'RECEIVED') {
          // 收货
          this.myStep = 3
        } else if (res.state === 'FINISH') {
          // 完成
          this.myStep = 4
        }
        // switch (res.state) {
        //   case 'NEW':
        //     this.myStep = 0
        //     break
        //   case 'PAYMENT':
        //     this.myStep = 1
        //     break
        //   case 'DELIVERY':
        //     this.myStep = 2
        //     break
        //   case 'RECEIVED':
        //     this.myStep = 3
        //     break
        //   case 'FINISH':
        //     this.myStep = 4
        // }
        if (res.state === 'CANCEL') {
          this.isCancle = true
          if (res.createTime) {
            this.aa = false
            this.bb = false
            this.cc = false
            this.dd = false
            this.ee = false
          } else if (res.paymentTime) {
            this.aa = true
            this.bb = true
            this.cc = false
            this.dd = false
            this.ee = false
          } else if (res.deliveryTime) {
            this.aa = true
            this.bb = true
            this.cc = true
            this.dd = false
            this.ee = false
          } else if (res.receivedTime) {
            this.aa = true
            this.bb = true
            this.cc = true
            this.dd = true
            this.ee = false
          } else if (res.finishTime) {
            this.aa = true
            this.bb = true
            this.cc = true
            this.dd = true
            this.ee = true
          }
        }
        res.products.forEach(item => {
          this.allPirce += item.num * item.unitPrice
        })
        this.myData = res
        console.log(this.myData)
      })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.order-state {
  .bread {
    font-size: 16px;
    color: black;
    font-weight: 600;
  }
  .state {
    .state-id {
      > div {
        margin: 20px 0 20px 0;
        color: rgb(102, 102, 122);
        font-weight: 600;
      }
    }
    .state-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .box-left {
        flex: 1;
        // width: 250px;
        height: 250px;
        border: 1px #666 solid;
        > div {
          // padding: 5px 0 0 18px;
          color: rgb(102, 102, 102);
          font-weight: 500;
          > div {
            color: red;
            font-size: 30px;
            text-align: center;
            margin-top: 75px;
          }
        }
      }
      .box-right {
         flex: 4;
        padding-left: 20px;
        // > div {
        //   margin: 20px 0 0 31px;
        // }
        .shoper-info {
          border: 2px dotted #ccc;
          padding: 10px 20px;
          overflow: hidden;
        }
      }
    }
    .box-down {
      border-top: rgb(217, 217, 217) 2px solid;
      .list {
        border: 1px solid #e6e6e6;
      }
      .list-head {
        display: flex;
        .name {
          flex: 0.5;
          text-align: center;
        }
        .list-name {
          justify-content: space-around;
          display: flex;
          flex: 1;
          div {
            width: 80px;
            text-align: center;
          }
        }
      }
    }
  }
}

.my-head {
  color: #92a6bb;
  font-size: 16px;
  background-color: #eff0f0;
  height: 32px;
  line-height: 30px;
}
.list-info {
  display: flex;
  align-items: center;
  margin: 10px 0 10px 40px;
  justify-content: center;
}
</style>
